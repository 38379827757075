<template>
    <v-container fluid fill-width :style="{'height':'90vh'}">
        <v-row class="mb-12">
            <v-divider color="black"/>
        </v-row>
        <v-row class="mt-12 ma-9">
            <v-col class="mt-6" cols=6>
                <h1 class="big-title">
                    Generating<br/>Durable Alpha<br/>In The Age<br/>of Software 
                </h1>
            </v-col>
        </v-row>
        <v-row :style="{'margin-top':'-50px'}" class="mr-9">
            <v-col>
                <v-row class="mt-0">
                    <v-col cols=6>
                    </v-col>
                    <v-col cols=6>
                        <h2 :style="{'font-weight':600}">We are entering an era of major transformation.</h2>
                        <h2 :style="{'text-align':'justify', 'font-size':'18px'}" class="mt-2 font-weight-regular">
                        OpenModeller recognizes our customers' existential need to move from manual, spreadsheet driven processes toward efficient software that lets them systematize better and faster investment decisions — or, alpha. Everyone else will find themselves in a perpetual game of catchup.
                        </h2>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'ProductSolution',
}
</script>

<style scoped>
.big-title {
    font-size: 48px;
    font-weight: 400;
    line-height: 1.2em;
}
</style>