<template>
    <v-container :style="{'height':'100vh'}">
        <v-row class="mt-12 mb-12">
            <v-divider color="black"/>
        </v-row>
        <v-row class="mt-12">
            <h1 class="benefits">What Makes<br/> Our Tools<br/>Powerful</h1>
        </v-row>
        <v-row class="mt-6">
            <v-col v-for="benefit in benefits" class="" :key="benefit.idx" cols=3>
                <single-benefit-card :idx="benefit.idx" :title="benefit.title" :subtitle="benefit.subtitle" class="mt-12"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import SingleBenefitCard from './SingleBenefitCard.vue'
export default {
  components: { SingleBenefitCard },
    name: 'BenefitsSummary',
    data: function() {
        return {
            benefits: [
                {
                    idx: 'A',
                    title: 'Immediate Value',
                    subtitle: 'Our tools deliver value out of the box, enabling faster and more efficient investment decisions through systematization and automation.',
                },
                {
                    idx: 'B',
                    title: 'Open Source ',
                    subtitle: 'Our core tooling is open source. CRE investors can access foundational software tools that until now, was only available to the largest organisations with $10m+ technology budgets.',
                },
                {
                    idx: 'C',
                    title: 'Technology\nAlpha',
                    subtitle: 'Embed persistent alpha by creating proprietary modules and enhancements that leverage your firm\'s investment capabilities and insights.',
                },
                {
                    idx: 'D',
                    title: 'Next Generation\nReady',
                    subtitle: 'Our tooling is a bridge to the next generation of analytics driven CRE investing. Data science in Python and the Pandas/SciPy stack for tomorrow, seamless integration with Excel for today.',
                },
            ],
        }
    },
}
</script>

<style lang="scss" scoped>
.benefits{
    font-size: 48px;
    font-weight: 300;
}
</style>