<template>
    <v-container fluid fill-height fill-width :style="{'min-height':'100vh'}">
        <v-divider color="black" />
        <v-row class="ma-9 mt-0">
            <v-spacer/>
            <h1 class="cta-title">Become a First Mover</h1>
            <v-spacer/>
            <v-spacer/>
        </v-row>
        <v-row align="center" class="ma-9 mb-12 mt-0">
            <v-col cols=6 />
            <v-col class="ml-12">
                <h2 :style="{'font-size':'16px'}" class="text--secondary font-weight-regular">Launching<br/>28th Feb 2022.</h2>
            </v-col>
            <v-col class="ml-9 mr-3">
                <v-btn
                    @mouseover="hovered = true"
                    @mouseleave="hovered = !hovered"
                    :outlined="!hovered"
                    large
                    :style="{'font-size':'15px'}"
                    class="rounded-0 text-none mr-9"
                    @click="$emit('signup')"
                    color="accent">
                    <h3 class="font-weight-thin">Book a Demo</h3>
                    <v-icon :class="hovered ? 'active ml-1 mt-1': 'mt-1 ml-1'" small>mdi-arrow-right</v-icon>
                    </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'BottomCTA',
    data: function(){
        return {
            hovered: false
        }
    }
}
</script>

<style scoped>
.active {
    transform: translate(5px);
}
.cta-title {
    font-size: 85px;
    font-weight: 400;
}

</style>