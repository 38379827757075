<template>
    <v-container fluid fill-width :style="{'height':'100vh'}">
        <v-row>
            <v-divider color="black">
            </v-divider>
        </v-row>
        <v-row class="ma-9">
            <h1 :style="{'margin-top':'60px', 'margin-bottom':'60px'}" class="ml-3 header">Solutions</h1>
        </v-row>
        <single-product-summary v-for="product in products" :key="product.title" :title="product.title" :subtitle="product.subtitle"/>
    </v-container>
</template>

<script>
import SingleProductSummary from './SingleProductSummary.vue'
export default {
    name: 'ProductList',
    components: {
        SingleProductSummary
    },
    data: function(){
        return {
            products: [
                {title: 'OpenModeller', subtitle: 'Fast and robust CRE financial modelling in Python, with seamless Microsoft Excel interopability.'},
                {title: 'CRE Data Schema', subtitle: 'Comprehensive CRE data schemas enabling analysis and aggregation of property cashflows at scale.'},
                {title: 'Feeds', subtitle: 'Standardised real estate data feeds powering mission critical business processes, data science and spreadsheets.'}
            ]
        }
    }
}
</script>

        ProductSolution
<style scoped>
.header {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.2em;
}
.text {
    font-size: 32px;
    font-weight: 400;
    line-height: 1.2em;
}
</style>