<template>
    <v-card flat min-width=220 min-height=150>
        <v-card-subtitle class="ml-3 mb-0">—{{idx}}</v-card-subtitle>
        <v-card-title class="ma-3 mt-0 pt-0">
            <h2 :style="{'white-space':'pre'}" class="font-weight-regular">{{ title }}</h2>
        </v-card-title>
        <v-card-text class="ma-3">
            <h3 class="font-weight-regular text--secondary">{{ subtitle }}</h3>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'SingleBenefitCard',
    props: {
        idx: {
            type: String,
            default: 'A',
        },
        title: {
            type: String,
            default: 'Day 1 Value',
        },
        subtitle: {
            type: String,
            default: 'Our platforms deliver value out of the box.',
        },
    }
}
</script>