<template>
    <v-container fluid fill-width>
        <v-row>
            <v-divider color="black"/>
        </v-row>
        <v-row class="mt-6 ml-12 mr-9 mb-12 " :style="{'margin-bottom':'10vh'}">
            <v-col cols=4>
                <v-row>
                <v-icon color="black">mdi-arrow-expand-right</v-icon>
                <h1 class="ml-3 font-weight-regular">
                    {{ title }}
                </h1>
                </v-row>
            </v-col>
            <v-col cols=3 />
            <v-col :style="{'margin-top':'4vh'}" cols=5>
                <h2 class="font-weight-regular">
                    {{ subtitle }}
                </h2>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'SingleProductSummary',
    props: {
        title: {
            type: String,
            default: 'Foundry',
        },
        subtitle: {
            type: String,
            default: 'Operating System for the Modern Enterprise.',
        },
    }
}
</script>