<template>
  <v-app>
    <top-menu-bar @signup="signup = true"/>
    <sign-up-popup @close="signup = false" v-show="signup"/>
    <v-main>
      <router-view @signup="signup = true"/>
    </v-main>
  </v-app>
</template>

<script>
import TopMenuBar from './components/navigation/TopMenuBar.vue';
import SignUpPopup from './components/signup/SignUpPopup.vue';

export default {
  components: { TopMenuBar, SignUpPopup },
  name: 'App',

  data: () => ({
    signup: false,
  }),
};
</script>
