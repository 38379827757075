<template>
    <div class="video-container">
        <video :style="{'opacity':0.15}" autoplay muted loop>
            <source src="@/assets/video.mp4" type="video/mp4" />
        </video>
        <v-container fluid fill-width class="caption ml-12 mr-12">
            <v-row class="ml-6" :style="{'margin-top':'30vh'}">
                <h2 :style="{'font-size':'64px', 'font-weight':400, 'line-height':'1.3em', 'white-space':'pre'}">{{ title }}</h2>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'TopHero',
    props: {
        title: {
            type: String,
            default: 'Next Generation Tooling\nFor CRE Investors\nAnd Brokers.',
        },
    }
}
</script>

<style scoped>
.hero-text {
    font-size: 64px;
    font-weight: 500;
}
.video-container {
    height: 100vh;
    width: 120vw;
    position: relative;
}

.video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
}

/* Just styling the content of the div, the *magic* in the previous rules */
.video-container .caption {
  z-index: 1;
  position: relative;
  color: black;
  padding: 10px;
}
</style>