<template>
  <v-app-bar :style="{'backdrop-filter':'blur(8px)'}" :color="'rgb(255, 255, 255,' + opacity + ')'" flat fixed>
    <h3 class="ma-3 font-weight-regular accent--text">OpenModeller</h3>
    <v-spacer/>
    <v-btn
      @mouseover="hovered = true"
      @mouseleave="hovered = !hovered"
      @click="$emit('signup')"
      :outlined="!hovered"
      large
      :style="{'font-size':'15px'}"
      class="rounded-0 text-none"
      color="accent">
      <v-icon :class="hovered ? 'active mr-1': 'mr-1'" small>mdi-arrow-top-left</v-icon>
      <h3 class="font-weight-thin">Demo</h3>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
    name: 'TopMenuBar',
    data: function(){
        return {
            hovered: false,
            opacity: 0.0
        }
    },
    mounted: function(){
      window.onscroll = () => {
        if (window.scrollY > 20){
          this.opacity = 0.8
        } else {
          this.opacity = 0.0
        }
      };
    },
}
</script>

<style scoped>
.active {
    transform: translate(-3.5px, -3.5px);
}

</style>