<template>
<div>
  <top-hero/>
  <summary-statement :text="summary" class="mt-12"/>
  <product-solution />
  <products-list/>
  <large-image :style="{'margin-top':'240px'}"/>
  <benefits-summary/>
  <bottom-CTA class="mt-12" @signup="$emit('signup')"/>
  <page-footer/>
</div>
</template>

<script>
import TopHero from '../components/landing/TopHero.vue'
import SummaryStatement from '../components/landing/SummaryStatement.vue'
import ProductSolution from '../components/landing/ProductSolution.vue'
import ProductsList from '../components/landing/ProductsList.vue'
import LargeImage from '../components/landing/LargeImage.vue'
import BenefitsSummary from '../components/landing/BenefitsSummary.vue'
import BottomCTA from '../components/landing/BottomCTA.vue'
import PageFooter from '../components/landing/PageFooter.vue'

  export default {
    name: 'Home',

    components: {
        TopHero,
        SummaryStatement,
        ProductSolution,
        ProductsList,
        BenefitsSummary,
        BottomCTA,
        PageFooter,
        LargeImage
    },

    data: function () {
      return {
        summary: 'We create open source tools that enable world class commercial real estate investors to systematize alpha generating investment decisions.',
      }
    },
  }
</script>,