<template>
    <v-container :style="{'height':'60vh'}" fluid fill-width>
        <v-row class="ma-9">
            <v-col cols=6 />
            <v-col cols=6>
                <h1 class="text">
                    {{ text }}
                </h1>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'SummaryStatement',
    props: {
        text: {
            type: String,
            default: 'We create open software that enables CRE investors to build and sustain competitive advantage in their investment decision making process.',
        },
    }
}
</script>

<style scoped>
.text {
    font-size: 32px;
    font-weight: 400;
    line-height: 1.2em;
}
</style>