<template>
    <v-overlay color="white" opacity=0.95>
        <v-card elevation=12 width=500 class="rounded-0">
            <v-container>
            <v-form ref="form">
                <v-row class="ma-3">
                    <h2 class="font-weight-regular">Book a Demo</h2>
                    <v-spacer/>
                    <v-icon @click="closeForm" small :style="{'position':'absolute','top':'10px','right':'10px'}">mdi-close</v-icon>
                </v-row>
                <v-row class="mt-6 mb-6">
                    <v-divider>
                    </v-divider>
                </v-row>
                <v-row justify="center" align="center" class="ml-3 mr-3">
                    <h3 class="mr-6 font-weight-regular">I am a</h3><v-select v-model="type" :style="{'max-width':'215px'}" :items='["Investor", "Broker", "Other"]'></v-select>
                </v-row>
                <v-row class="ma-3 mt-3 mr-4">
                    <v-text-field :rules="[v => !!v || 'Required Field']" v-model="name" append-icon="mdi-account" class="mr-3" label="Name"/>
                    <v-text-field :rules="[v => !!v || 'Required Field']" type="email" v-model="email" append-icon="mdi-email" class="ml-3" label="Email"/>
                </v-row>
                <v-row class="ma-3 mt-9">
                    <v-btn @click="submitForm" :style="{'background':'rgb(255, 255, 255, 0.1)'}" width=450 large class="rounded-0 mr-3"><h3 class="white--text text-none font-weight-regular"><v-icon v-if="success">mdi-check</v-icon>{{ success ? '' : 'Submit' }}</h3></v-btn>
                </v-row>
            </v-form>
            </v-container>
        </v-card>
    </v-overlay>
</template>

<script>
import axios from 'axios'

export default {
    name: 'SignUpPopup',
    data: function(){
        return {
            type: 'Investor',
            name: null,
            email: null,
            success: false
        }
    },
    methods: {
        closeForm: function(){
            this.$refs.form.reset()
            this.$emit('close')
        },
        submitForm: async function(){
            var validated = this.$refs.form.validate()
            if (validated){
                try {
                await axios.post("https://formspree.io/f/xgedegwv", {companytype: this.type, name: this.name, email: this.email})
                this.success = true
                setTimeout(() => {
                    this.$emit('close')
                }, 500);
                } catch (err) {
                    console.log(err);
                    }
                }
            }
    }
}
</script>